// UpdateBeneficiaryModal.js
import React, { useState, useEffect } from 'react';
import { Box, Button, TextField, Typography, Modal, CircularProgress, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import Alert from '@mui/material/Alert';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const UpdateBeneficiaryModal = ({ open, onClose, beneficiary }) => {
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        birthDate: '',
        diagnosis: '',
        // vigilanceState: '',
        school: '',
        sex: '',
    });
    const [loading, setLoading] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        console.log('ladate',beneficiary.birth_date)
        if (beneficiary) {
            setFormData({
                firstName: beneficiary.first_name || '',
                lastName: beneficiary.last_name || '',
                birthDate: beneficiary.birth_date || '',
                diagnosis: beneficiary.illnesses || '',
                // vigilanceState: beneficiary.vigilance_state || '',
                school: beneficiary.school || '',
                sex: beneficiary.sex || '',
            });
        }
    }, [beneficiary]);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        setSuccessMessage('');
        setErrorMessage('');

        try {
            const response = await fetch(apiUrl+`beneficiaries/${beneficiary.id}`, {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    first_name: formData.firstName,
                    last_name: formData.lastName,
                    birth_date: formData.birthDate,
                    illnesses: formData.diagnosis,
                    // vigilance_state: formData.vigilanceState,
                    school: formData.school,
                    sex: formData.sex,
                }),
            });

            if (response.ok) {
                const data = await response.json();
                setSuccessMessage(data.message || 'Beneficiary updated successfully!');
                onClose(true); // true indicates that update was successful
            } else {
                throw new Error('Failed to update beneficiary');
            }
        } catch (error) {
            console.error('Update error:', error);
            setErrorMessage(error.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Modal
            open={open}
            onClose={() => onClose(false)} // false indicates that update was not made
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="div">
                    Update Beneficiary
                </Typography>
                {loading ? (
                    <CircularProgress />
                ) : (
                    <form onSubmit={handleSubmit}>
                        <TextField fullWidth margin="normal" label="Prénom" name="firstName" value={formData.firstName} onChange={handleChange} />
                        <TextField fullWidth margin="normal" label="Nom" name="lastName" value={formData.lastName} onChange={handleChange} />
                        <TextField fullWidth margin="normal" label="Date de naissance" name="birthDate" type="date" InputLabelProps={{ shrink: true }} value={formData.birthDate.split(' ')[0]} onChange={handleChange} />
                        <TextField fullWidth margin="normal" label="Diagnostic(s)" name="diagnosis" value={formData.diagnosis} onChange={handleChange} />
                        {/*<TextField fullWidth margin="normal" label="État de vigilance" name="vigilanceState" value={formData.vigilanceState} onChange={handleChange} />*/}
                        <TextField fullWidth margin="normal" label="Établissement scolaire" name="school" value={formData.school} onChange={handleChange} />
                        <FormControl fullWidth margin="normal">
                            <InputLabel>Sexe</InputLabel>
                            <Select label="Sexe" name="sex" value={formData.sex} onChange={handleChange}>
                                <MenuItem value="M">Masculin</MenuItem>
                                <MenuItem value="F">Féminin</MenuItem>
                                    <MenuItem value="X">Non spécifié</MenuItem>
                        </Select>
                    </FormControl>
                    <Box mt={2} display="flex" justifyContent="space-between">
                    <Button variant="outlined" onClick={() => onClose(false)}>Annuler</Button>
                    <Button type="submit" variant="contained">Mettre à jour</Button>
                    </Box>
                    </form>
                    )}
                {successMessage && (
                    <Alert severity="success" sx={{ mt: 2 }}>{successMessage}</Alert>
                )}
                {errorMessage && (
                    <Alert severity="error" sx={{ mt: 2 }}>{errorMessage}</Alert>
                )}
            </Box>
        </Modal>
    );
};

export default UpdateBeneficiaryModal;
