    import React, { useState, useEffect } from 'react';
    import {
        Button, Dialog, DialogActions, DialogContent, DialogContentText,
        DialogTitle, TextField, List, ListItem, ListItemText,
        CircularProgress, Snackbar, Typography, Box
    } from '@mui/material';
    import Layout from "./Layout";

    const SuggestionsList = () => {
        const [suggestions, setSuggestions] = useState([]);
        const [selectedSuggestion, setSelectedSuggestion] = useState(null);
        const [newAnswer, setNewAnswer] = useState('');
        const [open, setOpen] = useState(false);
        const [isLoading, setIsLoading] = useState(false);
        const [snackbarOpen, setSnackbarOpen] = useState(false);
        const [snackbarMessage, setSnackbarMessage] = useState('');
        const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
        const apiUrl = process.env.REACT_APP_API_URL;

        useEffect(() => {
            const fetchSuggestions = async () => {
                try {
                    const response = await fetch(`${apiUrl}suggestions/pending`);
                    if (!response.ok) {
                        throw new Error('Failed to fetch suggestions');
                    }
                    const data = await response.json();
                    console.log('Fetched suggestions:', data); // Add this line to debug
                    setSuggestions(data);
                } catch (error) {
                    console.error('Error fetching suggestions:', error);
                }
            };

            fetchSuggestions();
        }, []);

        const fetchSuggestions = async () => {
            try {
                const response = await fetch(`${apiUrl}suggestions/pending`);
                const data = await response.json();
                setSuggestions(data);
            } catch (error) {
                console.error('Error fetching suggestions:', error);
            }
        };

        const handleSuggestionClick = (suggestion) => {
            setSelectedSuggestion(suggestion);
            setNewAnswer(suggestion.answer);
            setOpen(true);
        };

        const handleSuggestionChange = (event) => {
            setNewAnswer(event.target.value);
        };

        const handleSuggestionSubmit = async () => {
            if (newAnswer?.trim() === '') {
                setConfirmDialogOpen(true);
            } else {
                await submitSuggestion();
            }
        };

        const submitSuggestion = async () => {
            setIsLoading(true);
            setConfirmDialogOpen(false); // Close the confirmation dialog if it's open
            try {
                const response = await fetch(`${apiUrl}suggestions/handle`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        suggestion_id: selectedSuggestion.id,
                        new_answer: newAnswer,
                    }),
                });

                if (!response.ok) {
                    const errorData = await response.json();
                    throw new Error(errorData.error || 'Failed to handle suggestion');
                }

                setSnackbarMessage('Suggestion handled successfully');
                setSnackbarOpen(true);
                setOpen(false);
                fetchSuggestions(); // Refresh suggestions list
            } catch (error) {
                setSnackbarMessage('Error handling suggestion');
                setSnackbarOpen(true);
                console.error('Error handling suggestion:', error);
            } finally {
                setIsLoading(false);
            }
        };

        const handleClose = () => {
            setOpen(false);
        };

        const handleSnackbarClose = () => {
            setSnackbarOpen(false);
        };

        const handleConfirmDialogClose = (confirmed) => {
            setConfirmDialogOpen(false);
            if (confirmed) {
                submitSuggestion();
            }
        };

        return (
            <Layout>
                <Box sx={{ padding: 3 }}>
                    <Typography variant="h4" gutterBottom>
                        Liste des Suggestions de Modifications
                    </Typography>
                    <Typography variant="subtitle1" gutterBottom>
                        Suggestions restantes à traiter : {suggestions.length}
                    </Typography>
                    <List>
                        {suggestions.map((suggestion) => (
                            <ListItem button onClick={() => handleSuggestionClick(suggestion)} key={suggestion.id}>
                                <ListItemText primary={`${suggestion.first_name} ${suggestion.last_name}`} />
                            </ListItem>
                        ))}
                    </List>

                    <Dialog open={open} onClose={handleClose}>
                        <DialogTitle>Suggestion de modification</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                Nom: {selectedSuggestion?.first_name} {selectedSuggestion?.last_name}
                            </DialogContentText>
                            <DialogContentText>
                                Question: {selectedSuggestion?.question}
                            </DialogContentText>
                            <DialogContentText>
                                Réponse actuelle: {selectedSuggestion?.answer}
                            </DialogContentText>
                            <TextField
                                fullWidth
                                multiline
                                value={newAnswer}
                                onChange={handleSuggestionChange}
                            />
                            <DialogContentText>
                                Suggestion proposée: {selectedSuggestion?.suggested_text}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose} color="primary">
                                Annuler
                            </Button>
                            <Button onClick={handleSuggestionSubmit} color="primary" disabled={isLoading}>
                                {isLoading ? <CircularProgress size={24} /> : 'Traiter la suggestion'}
                            </Button>
                        </DialogActions>
                    </Dialog>

                    <Dialog open={confirmDialogOpen} onClose={() => handleConfirmDialogClose(false)}>
                        <DialogTitle>Confirmer l'envoi</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                La réponse est vide. Voulez-vous vraiment envoyer une réponse vide dans le carnet de bord ?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => handleConfirmDialogClose(false)} color="primary">
                                Annuler
                            </Button>
                            <Button onClick={() => handleConfirmDialogClose(true)} color="primary" autoFocus>
                                Confirmer
                            </Button>
                        </DialogActions>
                    </Dialog>

                    <Snackbar
                        open={snackbarOpen}
                        autoHideDuration={6000}
                        onClose={handleSnackbarClose}
                        message={snackbarMessage}
                    />
                </Box>
            </Layout>
        );
    };

    export default SuggestionsList;
