import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";

import { FormProvider } from "./Context";
import routes from './routes';
import {AuthProvider} from "./AuthContext";

function App() {
    return (
        <Router>
            <AuthProvider>
            <FormProvider>
                <Routes>
                    {routes.map((route, index) => (
                        <Route key={index} path={route.path} element={route.element} />
                    ))}
                </Routes>
            </FormProvider>
            </AuthProvider>
        </Router>
    );
}

export default App;
