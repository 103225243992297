import React, { useState, useContext } from 'react';
import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import DescriptionIcon from '@mui/icons-material/Description';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Snackbar from '@mui/material/Snackbar';
import { exportPdf } from './exportUtils';
import { FormContext } from './Context';
import { useMediaQuery, styled } from '@mui/material';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    minWidth: '150px',
    maxWidth: '150px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    [theme.breakpoints.down('sm')]: {
        minWidth: '100px',
        maxWidth: '100px',
    },
}));

const NameTableCell = styled(StyledTableCell)({
    minWidth: '200px',
    maxWidth: '200px',
});

const BrTableCell = styled(StyledTableCell)({
    minWidth: '100px',
    maxWidth: '100px',
});

const CompletedTableCell = styled(StyledTableCell)({
    minWidth: '150px',
    maxWidth: '150px',
});

const ExportTableCell = styled(StyledTableCell)({
    minWidth: '100px',
    maxWidth: '100px',
});
const apiUrl = process.env.REACT_APP_API_URL;
function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

export default function BeneficiaryTable({ beneficiaries, handleRowClick, handleRowDoubleClick, fetchBeneficiaries }) {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const { setSelectedBeneficiaryId } = useContext(FormContext);
    const isSmallScreen = useMediaQuery('(max-width:600px)');
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('PRENOM');

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleGenerateCarnet = async (beneficiary) => {
        setLoading(true);
        setError(null);
        try {
            const response = await fetch(apiUrl+'generate-carnet', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ PERSONNE_ID: beneficiary.PERSONNE_ID }),
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error || 'Failed to generate carnet');
            }

            await fetchBeneficiaries(); // Refresh the beneficiaries list
        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div style={{ padding: '20px', margin: '20px' }}>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <NameTableCell>
                            <TableSortLabel
                                active={orderBy === 'PRENOM'}
                                direction={orderBy === 'PRENOM' ? order : 'asc'}
                                onClick={(event) => handleRequestSort(event, 'PRENOM')}
                            >
                                Nom
                            </TableSortLabel>
                        </NameTableCell>
                        <BrTableCell>
                            <TableSortLabel
                                active={orderBy === 'BR'}
                                direction={orderBy === 'BR' ? order : 'asc'}
                                onClick={(event) => handleRequestSort(event, 'BR')}
                            >
                                BR
                            </TableSortLabel>
                        </BrTableCell>
                        <CompletedTableCell align="right">Complété</CompletedTableCell>
                        <ExportTableCell>Export</ExportTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {stableSort(beneficiaries, getComparator(order, orderBy)).map((beneficiary) => (
                        <TableRow
                            key={beneficiary.PERSONNE_ID}
                            onDoubleClick={() => handleRowDoubleClick(beneficiary)}
                        >
                            <NameTableCell>{`${beneficiary.PRENOM} ${beneficiary.NOM}`}</NameTableCell>
                            <BrTableCell>{`${beneficiary.BR}`}</BrTableCell>
                            <CompletedTableCell align="right">
                                {beneficiary.carnet_de_bord_completed ? (
                                    <Link onClick={() => handleRowClick(beneficiary.PERSONNE_ID)} style={{ cursor: 'pointer' }}>
                                        Afficher carnet de bord
                                    </Link>
                                ) : (
                                    <Button onClick={() => handleGenerateCarnet(beneficiary)} disabled={loading}>
                                        {loading ? <CircularProgress size={24} /> : 'Générer carnet de bord'}
                                    </Button>
                                )}
                            </CompletedTableCell>
                            <ExportTableCell>
                                <Button onClick={() => exportPdf(beneficiary)}>
                                    <DescriptionIcon />
                                </Button>
                            </ExportTableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            {error && (
                <Snackbar
                    open={!!error}
                    autoHideDuration={6000}
                    onClose={() => setError(null)}
                    message={error}
                />
            )}
        </div>
    );
}
