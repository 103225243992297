import React, { useEffect, useState, useContext } from 'react';
import './formulaire.scss';
import Img from './assets/img/wheelchair.png';
import Stepper from "./Stepper";
import QuestionCard from "./QuestionCard";
import { useParams } from "react-router-dom";
import Layout from "./Layout";
import BeneficiaryTable from './BeneficiaryTable';
import BeneficiaryListToggle from "./BeneficiaryListToggle";
import { FormContext } from './Context';

const Formulaire = () => {
    const { selectedBeneficiaryId, questionsData, setQuestionsData } = useContext(FormContext);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [currentAnswer, setCurrentAnswer] = useState("");
    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        if (selectedBeneficiaryId) {
            const fetchQuestions = async () => {
                try {
                    const response = await fetch(apiUrl + 'questions_polycap');
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }

                    let data = await response.json();
                    data = data.sort((a, b) => a.order - b.order);
                    setQuestionsData(data);
                } catch (error) {
                    console.error('Error fetching questions:', error);
                }
            };

            fetchQuestions();
        }
    }, [selectedBeneficiaryId, setQuestionsData]);

    const onCategorySelect = async (category) => {
        const newIndex = questionsData.findIndex(question => question.category === category);
        if (newIndex !== -1) {
            await saveCurrentAnswer(currentAnswer);
            await handleNavigation(newIndex);
        }
    };

    const onSubCategorySelect = async (subCategory) => {
        const newIndex = questionsData.findIndex(question => question.subcategory === subCategory);
        if (newIndex !== -1) {
            await saveCurrentAnswer(currentAnswer);
            await handleNavigation(newIndex);
        }
    };

    function saveCurrentAnswer(updatedAnswer) {
        return new Promise((resolve, reject) => {
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    answer: updatedAnswer
                })
            };
            let url = apiUrl + 'questions/' + questionsData[currentQuestionIndex].id + '/' + selectedBeneficiaryId;
            fetch(url, requestOptions)
                .then(response => response.json())
                .then(data => {
                    console.log("Mise à jour réussie:");
                    resolve(data);
                })
                .catch(error => {
                    console.error("Erreur lors de la mise à jour:", error);
                    reject(error);
                });
        });
    }

    const handleNavigation = async (newIndex) => {
        setCurrentQuestionIndex(newIndex);
    };

    const handleNextQuestion = () => {
        const newIndex = currentQuestionIndex + 1 < questionsData.length ? currentQuestionIndex + 1 : currentQuestionIndex;
        handleNavigation(newIndex);
    };

    const handlePreviousQuestion = () => {
        const newIndex = currentQuestionIndex > 0 ? currentQuestionIndex - 1 : 0;
        handleNavigation(newIndex);
    };

    let categories = [...new Set(questionsData.map(item => item.category))];
    const categoriesWithSubcategories = categories.map(category => ({
        category,
        subCategories: [...new Set(questionsData.filter(item => item.category === category).map(item => item.subcategory))]
    }));

    return (
        <Layout>
            <div className="mainContent">
                <div className="listBeneficiaire">
                    {/*<BeneficiaryTable beneficiaries={beneficiaries} fetchBeneficiaries={fetchBeneficiaries}/>*/}
                    <BeneficiaryListToggle />
                </div>
                <div className="formulaire">

                    <div className="formulaire-title">
                        <h6 className="text-5xl text-white text-center">Carnet de Bord</h6>
                    </div>

                    <div className="formulaire-main w-screen">
                        <Stepper
                            categoriesWithSubcategories={categoriesWithSubcategories}
                            currentQuestion={questionsData[currentQuestionIndex]}
                            onCategorySelect={onCategorySelect}
                            onSubCategorySelect={onSubCategorySelect}
                        />
                        {currentQuestionIndex < questionsData.length && (
                            <QuestionCard
                                item={questionsData[currentQuestionIndex]}
                                questionId={questionsData[currentQuestionIndex].id}
                                beneficiaryId={selectedBeneficiaryId}
                                onBack={handlePreviousQuestion}
                                onContinue={handleNextQuestion}
                                setCurrentAnswer={setCurrentAnswer}
                                onSaveAnswer={saveCurrentAnswer}
                                QuestionIsImageRequired={questionsData[currentQuestionIndex].imagerequired} // Ajout de la propriété
                            />
                        )}
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default Formulaire;
