import React, { useState, useEffect } from 'react';
import './questionCard.scss'; // Assurez-vous que le chemin d'accès est correct
import { CircularProgress, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Snackbar, IconButton, Popover } from "@mui/material";
import InfoIcon from '@mui/icons-material/Info';

const QuestionCard = (props) => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [visible, setVisible] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [answer, setAnswer] = useState('');
    const [suggestion, setSuggestion] = useState('');
    const [open, setOpen] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [answerId, setAnswerId] = useState(null); // Ajoutez un état pour stocker answer_id
    const [imageFile, setImageFile] = useState(null); // État pour stocker l'image
    const [anchorEl, setAnchorEl] = useState(null); // État pour le popover
    const [informationsList, setInformationsList] = useState([]);
    const handleAnswerChange = (event) => {
        setAnswer(event.target.value);
        props.setCurrentAnswer(event.target.value);
    };

    const handleImageChange = (event) => {
        setImageFile(event.target.files[0]);
    };

    const handleSave = async (callback) => {
        setIsLoading(true);
        try {
            const formData = new FormData();
            formData.append('answer', answer);
            if (imageFile) {
                formData.append('image', imageFile);
            }

            await fetch(`${apiUrl}questions/${props.questionId}/${props.beneficiaryId}`, {
                method: 'POST',
                body: formData,
            });

            console.log("Réponse mise à jour avec succès");
            if (callback && typeof callback === 'function') {
                callback();
            }
        } catch (error) {
            console.error("Erreur lors de la mise à jour de la réponse :", error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleSuggestionChange = (event) => {
        setSuggestion(event.target.value);
    };

    const handleSuggestionSubmit = async () => {
        setIsLoading(true);
        try {
            const suggestionData = {
                answer_id: answerId, // Utilisez answerId ici
                suggested_text: suggestion,
            };

            console.log('Sending suggestion data:', suggestionData);

            const response = await fetch(`${apiUrl}suggestions`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(suggestionData),
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error || 'Failed to submit suggestion');
            }

            const responseData = await response.json();
            console.log('Server response:', responseData);

            setSnackbarMessage('Suggestion submitted successfully');
            setSnackbarOpen(true);
            setOpen(false);
        } catch (error) {
            setSnackbarMessage('Error submitting suggestion');
            setSnackbarOpen(true);
            console.error("Erreur lors de la soumission de la suggestion :", error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    useEffect(() => {
        console.log('update en cours', props.questionId, props.beneficiaryId);

        loadJsonData(`${apiUrl}questions/${props.questionId}/${props.beneficiaryId}`)
            .then(jsonData => {
                console.log('jsonData:', jsonData);
                setData(jsonData);
                setAnswer(jsonData.answer || '');
                setAnswerId(jsonData.id);
                props.setCurrentAnswer(jsonData.answer || '');
                console.log('answer =>', jsonData.answer);

                // Mise à jour de informationsList
                if (props.item && typeof props.item.i === 'string') {
                    console.log('props.item.i:', props.item.i);

                    try {
                        const parsedList = JSON.parse(props.item.i);
                        console.log('parsedList:', parsedList);

                        if (Array.isArray(parsedList)) {
                            setInformationsList(parsedList);
                            console.log('Informations list is an array:', parsedList);
                        } else {
                            console.warn('Parsed informations is not an array:', parsedList);
                        }
                    } catch (error) {
                        console.error("Parsing error on informations:", error);
                    }
                } else {
                    console.warn('props.item.i is not a string:', props.item.i);
                }
            })
            .catch(err => {
                setError(err.message);
                console.error('Error fetching the data:', err);
            });
    }, [props.questionId, props.beneficiaryId, props.item]);

    function loadJsonData(filePath) {
        return new Promise((resolve, reject) => {
            setIsLoading(true);
            fetch(filePath)
                .then(response => {
                    if (response.ok) {
                        return response.json();
                    } else {
                        throw new Error('Failed to load file: ' + filePath);
                    }
                })
                .then(data => {
                    setIsLoading(false);
                    resolve(data);
                })
                .catch(error => {
                    setIsLoading(false);
                    reject(error);
                });
        });
    }

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const openPopover = Boolean(anchorEl);

    if (error) {
        return <div>Error: {error}</div>;
    }

    if (!data) {
        return <div>Loading...</div>;
    }
    if (isLoading) {
        return <div><CircularProgress /></div>;
    }


    try {
        // Vérifie si props.item.i est une chaîne
        if (typeof props.item.i === 'string') {
            console.log('props.item.i:', props.item.i);

            // Tente de parser la chaîne JSON
            const informationsList = JSON.parse(props.item.i);

            // Log le contenu de l'objet parsé
            console.log('informationsList:', informationsList);
            console.log('length',informationsList.length);

            // Vérifie si le résultat du parsing est bien un tableau
            if (Array.isArray(informationsList)) {
                console.log('Informations list is an array:', informationsList);
            } else {
                console.warn('Parsed informations is not an array:', informationsList);
            }
        } else {
            console.warn('props.item.i is not a string:', props.item.i);
        }
    } catch (error) {
        console.error("Parsing error on informations:", error);
    }


    return (
        <div>
            {visible && (
                <div className="flex items-center justify-center h-screen w-full bg-transparent">
                    <div className="card mx-auto text-white h-screen w-full">
                        <div className="flex justify-between items-center mb-6">
                            <div className="text-3xl font-bold">{props.item.category}</div>
                            <div className="text-xl font-bold">{props.item.subcategory}</div>
                            {informationsList.length > 0 ?
                                <IconButton onClick={handlePopoverOpen}>
                                <InfoIcon className="text-2xl" />
                            </IconButton>:<p></p>}
                            <Popover
                                open={openPopover}
                                anchorEl={anchorEl}
                                onClose={handlePopoverClose}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                }}
                            >
                                <div style={{ padding: '10px', maxWidth: '300px' }}>
                                    {informationsList.length > 0 ? (
                                        informationsList.map((info, index) => (
                                            <p key={index}>{info}</p>
                                        ))
                                    ) : (
                                        <p>..</p>
                                    )}
                                </div>
                            </Popover>
                        </div>
                        <div className="flex mb-4">
                            <div className="mr-4 flex-shrink-0">
                                <span className="block w-10 h-10 rounded-full bg-yellow-400 text-xl text-center leading-10">{props.item.order}</span>
                            </div>
                            <div className="flex-grow">
                                <p>{props.item.question}</p>
                            </div>
                        </div>
                        {props.QuestionIsImageRequired ? (
                            <div className="flex justify-center">
                                {data.image_url ? (
                                    <img src={`http://localhost:8000/storage/${data.image_url}`} alt="answer" className="max-w-full h-auto" onError={(e) => { e.target.onerror = null; e.target.src = 'fallback-image-url.jpg'; }} />
                                ) : (
                                    <div>
                                        <input type="file" onChange={handleImageChange} />
                                    </div>
                                )}
                            </div>
                        ) : (
                            <div>
                                <textarea
                                    className="w-full h-32 p-4 bg-white text-gray-800 rounded-lg focus:outline-none"
                                    value={answer}
                                    onChange={handleAnswerChange}
                                />
                            </div>
                        )}
                        <div className="flex justify-end">
                            <button
                                className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded-full"
                                onClick={() => handleSave(props.onBack)}
                            >
                                Précédent
                            </button>
                            <button
                                className="bg-yellow-500 hover:bg-yellow-600 text-white font-bold py-2 px-4 rounded-full"
                                onClick={() => handleSave(props.onContinue)}
                            >
                                Continuer
                            </button>
                            <button
                                className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-full"
                                onClick={handleClickOpen}
                            >
                                Suggérer une modification
                            </button>
                        </div>
                    </div>
                </div>
            )}

            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Suggestion de modification</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Proposez votre modification pour la réponse actuelle.
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="suggestion"
                        label="Suggestion"
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={suggestion}
                        onChange={handleSuggestionChange}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Annuler
                    </Button>
                    <Button onClick={handleSuggestionSubmit} color="primary" disabled={isLoading}>
                        {isLoading ? <CircularProgress size={24} /> : 'Soumettre'}
                    </Button>
                </DialogActions>
            </Dialog>

            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                message={snackbarMessage}
            />
        </div>
    );
};

export default QuestionCard;
