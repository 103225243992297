import React, { createContext, useState } from 'react';

export const FormContext = createContext();

export const FormProvider = ({ children }) => {
    const [selectedBeneficiaryId, setSelectedBeneficiaryId] = useState(null);
    const [questionsData, setQuestionsData] = useState([]);

    return (
        <FormContext.Provider value={{ selectedBeneficiaryId, setSelectedBeneficiaryId, questionsData, setQuestionsData }}>
            {children}
        </FormContext.Provider>
    );
};
