import React, { useState, useEffect } from 'react';
import { Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle, Snackbar, Table, TableBody, TableCell, TableHead, TableRow, Checkbox, Autocomplete } from '@mui/material';
import Layout from './Layout';

const QuestionManager = () => {
    const [questions, setQuestions] = useState([]);
    const [open, setOpen] = useState(false);
    const [newQuestion, setNewQuestion] = useState({ question: '', category: '', subcategory: '', order: '', imagerequired: false, i: [] });
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');

    useEffect(() => {
        fetchQuestions();
    }, []);

    const fetchQuestions = async () => {
        try {
            const response = await fetch('http://localhost:8000/api/questions_polycap');
            const data = await response.json();
            setQuestions(data);
        } catch (error) {
            console.error('Error fetching questions:', error);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setNewQuestion((prev) => ({ ...prev, [name]: value }));
    };

    const handleCheckboxChange = (e) => {
        const { name, checked } = e.target;
        setNewQuestion((prev) => ({ ...prev, [name]: checked }));
    };

    const handleAdditionalInfoChange = (index, value) => {
        const newInfo = [...newQuestion.i];
        newInfo[index] = value;
        setNewQuestion((prev) => ({ ...prev, i: newInfo }));
    };

    const addAdditionalInfoField = () => {
        setNewQuestion((prev) => ({ ...prev, i: [...prev.i, ''] }));
    };

    const handleSaveQuestion = async () => {
        try {
            console.log('Sending new question data:', newQuestion); // Ajout du log pour vérifier les données envoyées
            const response = await fetch('http://localhost:8000/api/questions', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(newQuestion),
            });
            if (!response.ok) {
                throw new Error('Failed to save question');
            }
            setSnackbarMessage('Question added successfully');
            setSnackbarOpen(true);
            setOpen(false);
            fetchQuestions();
        } catch (error) {
            setSnackbarMessage('Error saving question');
            setSnackbarOpen(true);
            console.error('Error saving question:', error);
        }
    };


    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const getUniqueValues = (key) => {
        return [...new Set(questions.map(question => question[key]))];
    };

    return (
        <Layout>
            <div>
                <Button variant="outlined" onClick={handleClickOpen}>
                    Add Question
                </Button>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Question</TableCell>
                            <TableCell>Category</TableCell>
                            <TableCell>Subcategory</TableCell>
                            <TableCell>Order</TableCell>
                            <TableCell>Image Required</TableCell>
                            <TableCell>Additional Info</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {questions.map((question) => (
                            <TableRow key={question.id}>
                                <TableCell>{question.question}</TableCell>
                                <TableCell>{question.category}</TableCell>
                                <TableCell>{question.subcategory}</TableCell>
                                <TableCell>{question.order}</TableCell>
                                <TableCell>{question.imagerequired ? 'Yes' : 'No'}</TableCell>
                                <TableCell>{JSON.stringify(question.i)}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                <Dialog open={open} onClose={handleClose}>
                    <DialogTitle>Add New Question</DialogTitle>
                    <DialogContent>
                        <TextField autoFocus margin="dense" name="question" label="Question" fullWidth onChange={handleChange} />
                        <Autocomplete
                            options={getUniqueValues('category')}
                            freeSolo
                            onChange={(event, value) => setNewQuestion(prev => ({ ...prev, category: value }))}
                            renderInput={(params) => (
                                <TextField {...params} margin="dense" name="category" label="Category" fullWidth onChange={handleChange} />
                            )}
                        />
                        <Autocomplete
                            options={getUniqueValues('subcategory')}
                            freeSolo
                            onChange={(event, value) => setNewQuestion(prev => ({ ...prev, subcategory: value }))}
                            renderInput={(params) => (
                                <TextField {...params} margin="dense" name="subcategory" label="Subcategory" fullWidth onChange={handleChange} />
                            )}
                        />
                        <TextField margin="dense" name="order" label="Order" fullWidth onChange={handleChange} />
                        <div>
                            <Checkbox
                                checked={newQuestion.imagerequired}
                                onChange={handleCheckboxChange}
                                name="imagerequired"
                                color="primary"
                            />
                            Image Required
                        </div>
                        {newQuestion.i.map((info, index) => (
                            <TextField
                                key={index}
                                margin="dense"
                                label={`Additional Info ${index + 1}`}
                                fullWidth
                                value={info}
                                onChange={(e) => handleAdditionalInfoChange(index, e.target.value)}
                            />
                        ))}
                        <Button onClick={addAdditionalInfoField}>Add More Info</Button>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={handleSaveQuestion} color="primary">
                            Save
                        </Button>
                    </DialogActions>
                </Dialog>
                <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose} message={snackbarMessage} />
            </div>
        </Layout>
    );
};

export default QuestionManager;
