import { Navigate } from 'react-router-dom';
import { useAuth } from './AuthContext';

const PrivateRoute = ({ children }) => {
    const { user, loading } = useAuth();

    if (loading) {
        return <div>Chargement...</div>; // Ou tout autre indicateur de chargement
    }

    return user ? children : <Navigate to="/login" replace />;
};
export default  PrivateRoute;

