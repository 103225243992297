import React, { useState, useEffect, useContext } from 'react';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import BeneficiaryTable from './BeneficiaryTable';
import UpdateBeneficiaryModal from "./UpdateBeneficiaryModal";
import { useNavigate } from "react-router-dom";
import { FormContext } from './Context';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

export default function BeneficiaryListToggle() {
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [beneficiaries, setBeneficiaries] = useState([]);
    const [selectedBeneficiary, setSelectedBeneficiary] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    const { setSelectedBeneficiaryId } = useContext(FormContext);
    const navigate = useNavigate();
    const theme = useTheme();
    const apiUrl = process.env.REACT_APP_API_URL;
    // Définir les points de rupture pour différentes largeurs d'écran
    const isXs = useMediaQuery(theme.breakpoints.down('xs'));
    const isSm = useMediaQuery(theme.breakpoints.between('xs', 'sm'));
    const isMd = useMediaQuery(theme.breakpoints.between('sm', 'md'));
    const isLg = useMediaQuery(theme.breakpoints.between('md', 'lg'));
    const isXl = useMediaQuery(theme.breakpoints.up('lg'));

    const fetchBeneficiaries = async () => {
        try {
            const response = await fetch(apiUrl+'beneficiaries');
            const data = await response.json();
            setBeneficiaries(data);
        } catch (error) {
            console.error('Error fetching beneficiaries:', error);
        }
    };

    useEffect(() => {
        fetchBeneficiaries();
    }, []);

    const handleDrawerToggle = () => {
        setDrawerOpen(!drawerOpen);
    };

    const handleRowClick = (beneficiaryId) => {
        setSelectedBeneficiaryId(beneficiaryId);
        if (window.location.pathname !== `/formulaire/${beneficiaryId}`) {
            navigate(`/formulaire/${beneficiaryId}`);
        } else {
            navigate(`/temp`);
            setTimeout(() => navigate(`/formulaire/${beneficiaryId}`), 100); // Naviguer après un petit délai
        }
    };

    const handleRowDoubleClick = (beneficiary) => {
        setSelectedBeneficiary(beneficiary);
        setModalOpen(true);
    };

    const handleClose = () => {
        setModalOpen(false);
        fetchBeneficiaries();
    };

    const getDrawerWidth = () => {
        if (isXs) return '100%';
        if (isSm) return '80%';
        if (isMd) return '60%';
        if (isLg) return '600%';
        return '40%';
    };

    return (
        <div>
            <Button onClick={handleDrawerToggle} style={{ margin: '16px' }}>
                {drawerOpen ? 'Cacher bénéficiaires' : 'Afficher bénéficiaires'}
            </Button>
            <Drawer
                anchor="left"
                open={drawerOpen}
                onClose={handleDrawerToggle}
                PaperProps={{
                    style: {
                        width: getDrawerWidth(), // Utiliser la fonction pour obtenir la largeur en fonction du point de rupture
                    },
                }}
            >
                <div style={{ width: 'auto', padding: '20px' }}>
                    <BeneficiaryTable
                        beneficiaries={beneficiaries}
                        fetchBeneficiaries={fetchBeneficiaries}
                        navigate={navigate}
                        handleRowClick={handleRowClick}
                        handleRowDoubleClick={handleRowDoubleClick}
                    />
                </div>
            </Drawer>
            {selectedBeneficiary && (
                <UpdateBeneficiaryModal
                    open={modalOpen}
                    onClose={handleClose}
                    beneficiary={selectedBeneficiary}
                />
            )}
        </div>
    );
}
