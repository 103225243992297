import React, {createContext, useContext, useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
    const [loading, setLoading] = useState(true);
    const [user, setUser] = useState(null);
    const navigate = useNavigate();
    useEffect(() => {
        // Initialisation de l'état d'authentification au démarrage

        const authToken = localStorage.getItem('authToken');
        if (authToken) {
            console.log('on est là');
        }else{

                navigate('/');
        }
        setLoading(false);
    }, []);
    const login = (token) => {
        // Ici, stockez le token JWT dans le localStorage ou sessionStorage
        localStorage.setItem('authToken', token);
        setUser({ token }); // Simplification: stockez plus d'infos si nécessaire
    };

    const logout = () => {
        localStorage.removeItem('authToken');
        setUser(null);

    };

    return (
        <AuthContext.Provider value={{ user, login, logout, loading }}>
            {children}
        </AuthContext.Provider>
    );
};
