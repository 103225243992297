import React, { useEffect, useState } from 'react';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import * as XLSX from 'xlsx';
import {AlignmentType, Document, Packer, Paragraph, TextRun} from 'docx';
const QuestionsTable = () => {
    const [categorizedQuestions, setCategorizedQuestions] = useState({});
    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        fetch(apiUrl+'answers')
            .then(response => response.json())
            .then(data => {
                // Appel de structureData pour transformer les données reçues
                const structuredData = structureData(data);
                setCategorizedQuestions(structuredData); // Mise à jour de l'état avec les données structurées
                console.log(categorizedQuestions);
            })
            .catch(error => console.error('Error fetching questions:', error));
    }, []);


    const exportPDF = () => {
        html2canvas(document.querySelector("#questions-table")).then(canvas => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF();
            pdf.addImage(imgData, 'PNG', 0, 0);
            pdf.save("questions.pdf");
        });
    };

    const exportWord = () => {
        let children = [];
        const beneficiary = {
            name: "NomDeFamille",
            firstName: "Prénom",
            birth_date: "01/01/2010",
            sex: "F",
            school: { name: "Nom Établissement Scolaire" },
            illness: "Diagnostic(s)",
            therapies: "Liste des thérapies",
            lieudevie: "Lieu de vie"
        };

        // Informations générales
        const generalInfo = [
            `Nom de l'enfant : ${beneficiary.name}`,
            `Prénom de l'enfant : ${beneficiary.firstName}`,
            `Date de naissance : ${beneficiary.birth_date}`,
            `Sexe : ${beneficiary.sex}`,
            `Établissement scolaire : ${beneficiary.school.name}`,
            `Diagnostic(s) : ${beneficiary.illness}`,
            `Caractéristique des déficiences : ${beneficiary.illness}`, // Utilisez une description plus appropriée si disponible
            `Epileptique : ...`, // Completez avec les informations disponibles
            `Thérapie : ${beneficiary.therapies}`,
            `Lieu de vie : ${beneficiary.lieudevie}`
        ];
        children.push(new Paragraph({
            children: [
                new TextRun({
                    text: "Informations Générales",
                    bold: true,
                    size: 32,
                })
            ],
            spacing: { after: 200 },
            alignment: AlignmentType.CENTER,
        }));
        const createInfoParagraph = (boldText, normalText) => {
            return new Paragraph({
                children: [
                    new TextRun({
                        text: boldText,
                        bold: true,
                    }),
                    new TextRun(" "),
                    new TextRun(normalText),
                ],
                spacing: { after: 200 },
            });
        };

        // Ajout des informations générales avec mise en forme spécifique
        children.push(createInfoParagraph("Nom de l'enfant :", beneficiary.name));
        children.push(createInfoParagraph("Prénom de l'enfant :", beneficiary.firstName));
        children.push(createInfoParagraph("Date de naissance :", beneficiary.birth_date));
        children.push(createInfoParagraph("Sexe :", beneficiary.sex));
        children.push(createInfoParagraph("Établissement scolaire :", beneficiary.school.name));
        children.push(createInfoParagraph("Diagnostic(s) :", beneficiary.illness));
        children.push(createInfoParagraph("Caractéristique des déficiences :", beneficiary.illness)); // Utilisez une description plus appropriée si disponible
        // Complétez avec d'autres informations de la même manière
        if (categorizedQuestions && Object.keys(categorizedQuestions).length > 0) {
            // Génération des paragraphes pour chaque catégorie et ses sous-catégories
            Object.entries(categorizedQuestions).forEach(([category, subcategories]) => {
                children.push(new Paragraph({
                    children: [new TextRun({
                        text: category,
                        font: "Times New Roman Bold",
                        bold: true,
                        size: 32,
                    })],
                    alignment: AlignmentType.CENTER,
                    spacing: { after: 200 },
                }));

                Object.entries(subcategories).forEach(([subcategory, questions]) => {
                    children.push(new Paragraph({
                        children: [new TextRun({
                            text: subcategory,
                            font: "Times New Roman Bold",
                            underline: true,
                            bold: true,
                            size: 22,
                        })],
                        spacing: { after: 100 },
                    }));

                    questions.forEach((question, index) => {
                        children.push(new Paragraph({
                            children: [new TextRun({
                                text: `${index + 1}. ${question.question_polycap.question} - ${question.answer}`,
                                size: 20,
                            })],
                            spacing: { after: 100 },
                        }));
                    });
                });
            });
        }

        // Création du document avec les sections générées
        const doc = new Document({
            sections: [{
                properties: {},
                children: children,
            }],
        });

        // Pack and save the document
        Packer.toBlob(doc).then(blob => {
            // Download the document
            const url = URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            link.download = "questions.docx";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        });
    };



    const exportExcel = () => {
        const worksheet = XLSX.utils.json_to_sheet(categorizedQuestions.map(question => ({
            Question: question.question_polycap.question,
            Answer: question.answer
        })));
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Questions");
        XLSX.writeFile(workbook, "questions.xlsx");
    };

    const structureData = (data) => {
        let categoriesMap = {};

        data.forEach(item => {
            const { category, subcategory } = item.question_polycap;
            if (!categoriesMap[category]) {
                categoriesMap[category] = {};
            }
            if (!categoriesMap[category][subcategory]) {
                categoriesMap[category][subcategory] = [];
            }
            categoriesMap[category][subcategory].push(item);
        });

        return categoriesMap;
    };

    return (
        <div id="questions-table">
            {Object.entries(categorizedQuestions).map(([category, subcategories]) => (
                <div key={category}>
                    <h2>{category}</h2>
                    {Object.entries(subcategories).map(([subcategory, questions]) => (
                        <div key={subcategory}>
                            <h3>{subcategory}</h3>
                            <ul>
                                {questions.map((question, index) => (
                                    <li key={index}>
                                       <b>{question.question_polycap.question} </b> <br/> {question.answer}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    ))}
                </div>
            ))}
            <button onClick={exportPDF}>Export PDF</button>
            <button onClick={exportExcel}>Export Excel</button>
            <button onClick={exportWord}>Export Word</button>

        </div>
    );
};

export default QuestionsTable;