import Dashboard from "./Dashboard";
import Formulaire from "./Formulaire";
import QuestionsTable from "./QuestionsTable";
import SuggestionsList from "./SuggetionsList";
import QuestionManager from "./QuestionManager";
import PrivateRoute from "./PrivateRoute";
import {Navigate} from "react-router-dom";
import Login from "./Login";

const routes = [
    { path: "/", element: <Login /> },
    { path: "/suggestions", element: <SuggestionsList /> },
    { path: "/dashboard", element: <Dashboard /> },
    { path: "/questions", element: <QuestionManager /> },
    { path: "/formulaire/:beneficiaryId", element: <Formulaire /> },
    { path: "/pdf", element: <PrivateRoute><QuestionsTable /></PrivateRoute> },
    { path: "*", element: <Navigate to="/login" replace /> }
];

export default routes;
